import { removeJwtToken } from "@/cookies/jwtCookieHelper"
import { removeJwtGovToken } from "@/cookies/jwtGovCookieHelper"
import { useCurrentProfileStore } from "@/stores/data/useCurrentProfileStore"
import { isDevelopmentMode } from "@/utils/environmentUtils"

export function logoutService(navigate: (path: string) => void) {
  const { reset } = useCurrentProfileStore.getState()

  reset()
  removeJwtToken()
  removeJwtGovToken()
  localStorage.clear()
  sessionStorage.clear()

  const paths = ["/"]
  const domains = [
    "",
    "localhost",
    "msqualifica.com.br",
    "msqualifica.ms.gov.br",
    "hom.msqualifica.ms.gov.br",
  ]

  paths.forEach((path) => {
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=")
      const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim()
      domains.forEach((domain) => {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path};domain=${domain}`
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path}` // Also clear without domain
      })
    })
  })

  if (!isDevelopmentMode()) {
    const clientId = process.env.NEXT_PUBLIC_AUTH_GOV_CLIENT_ID
    const govRedirect = process.env.NEXT_PUBLIC_AUTH_GOV_REDIRECT
    window.location.href = `${govRedirect}/sso/sair?client_id=${clientId}&redirect_uri=https://msqualifica.ms.gov.br/`
  } else {
    navigate("/?refresh=true")
  }
}
