"use client"

import { useAuth } from "@/contexts/AuthProvider"
import { setStateGov } from "@/cookies/stateGovHelper"
import { AccountCircle } from "@mui/icons-material"
import { Button, CircularProgress, SxProps, Theme } from "@mui/material"
import { useEffect, useState } from "react"

interface LoginButtonProps {
  buttonsSx?: SxProps<Theme>
  adictionalRedirect?: string
  altText?: string
  fullWidth?: boolean
  startIcon?: JSX.Element
  id?: string
  variant?: "text" | "outlined" | "contained" | undefined
}

export default function LoginButton({
  buttonsSx,
  altText = "Entrar",
  fullWidth = false,
  startIcon = <AccountCircle />,
  id = "btn_entrar",
  variant = "contained",
}: LoginButtonProps) {
  const { isLoading, isLoggedIn } = useAuth()

  const clientId = process.env.NEXT_PUBLIC_AUTH_GOV_CLIENT_ID
  const redirectFailed = process.env.NEXT_PUBLIC_AUTH_GOV_REDIRECT_FAILED
  const govRedirect = process.env.NEXT_PUBLIC_AUTH_GOV_REDIRECT

  const [state, setState] = useState<string>("")

  useEffect(() => {
    const array = new Uint32Array(1)
    const generatedState = window.crypto.getRandomValues(array)[0].toString(16)

    setState(generatedState)
  }, [])

  const handleAuthGOV = () => {
    setStateGov(state)
    if (clientId && govRedirect && redirectFailed && state) {
      window.location.href = `${govRedirect}/sso/cidadao?client_id=${clientId}&state=${state}&redirect_failed=${redirectFailed}`
    } else {
      console.error(
        "Variáveis de ambiente de autenticação ou state não configurados corretamente.",
      )
    }
  }

  // Exibe "Carregando..." com um spinner enquanto o estado está em carregamento
  if (isLoading) {
    return <CircularProgress />
  }

  if (!isLoggedIn) {
    return (
      <Button
        onClick={handleAuthGOV}
        fullWidth={fullWidth}
        variant={variant}
        startIcon={startIcon}
        sx={{
          backgroundColor: "white",
          color: "blueMsGov.main",
          borderColor: "white",
          textTransform: "uppercase",
          ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          display: "inline-flex",
          ...buttonsSx,
        }}
        id={id}
      >
        {altText}
      </Button>
    )
  }

  return null
}
