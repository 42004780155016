import { HomeProfile } from "@/interfaces/enums/HomeProfile"
import { create } from "zustand"

type Store = {
  homeProfile: HomeProfile
  setProfile: (value: HomeProfile) => void
  isApplicant: () => boolean
  isEmployer: () => boolean
  toggleProfile: () => void
}

export const useHomeProfileStore = create<Store>((setState, getState) => ({
  homeProfile: HomeProfile.Applicant,
  setProfile: (value) => setState(() => ({ homeProfile: value })),
  isApplicant: () => getState().homeProfile === HomeProfile.Applicant,
  isEmployer: () => getState().homeProfile === HomeProfile.Employer,
  toggleProfile: () => {
    const currentProfile = getState().isApplicant()
      ? HomeProfile.Employer
      : HomeProfile.Applicant

    setState(() => ({
      homeProfile: currentProfile,
    }))
  },
}))
